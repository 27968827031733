import React from 'react'
import OurLocation from '../OurLocation/OurLocation'

const ContactUs = () => {
  return (
    <div>
      <div className="about mt-3">
            <div className="container">
        
                <div className="section-header">
                    
                    <h2>Our Location </h2>

                    </div>
                    <OurLocation/>
                    
                    
                    </div></div>
    </div>
  )
}

export default ContactUs
