import React, { useEffect } from 'react'

const Mechanical = () => {
    useEffect(()=>{
        window.scroll(0,0)
          },[])
  return (
    <div>
      <div className="about mt-3">
            <div className="container">
                <div className="section-header">
                    <h1 className='text-center'>Mechanical</h1>
                    <h2>Mechanical Parts Trading</h2>
                    <p className='text-left'>
                    Mechanical parts trading involves sourcing and distributing essential components like bearings, gears, seals, and fasteners to various industries. These parts are crucial in maintaining and repairing machinery across sectors such as automotive, aerospace, manufacturing, and construction.</p>               
                     </div>
                
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="about-img">
                            <img src="/images/mechanical/mc (1).jpeg" alt="" className="img-fluid"/>
                        </div>
                        <div className="about-content">
                            <h2 className='text-center'>Our Offerings</h2>

                           <h2>Diverse Product Range:</h2>
                           <p> We provide a comprehensive selection of high-quality mechanical spare parts, including standard and custom components.
                           </p>
                           <h2>Quality Assurance:</h2>
                           <p> All products are sourced from reputable manufacturers and undergo rigorous quality checks.
                           </p>
                           <h2>Competitive Pricing:</h2>
                           <p>We offer competitive prices, ensuring value without compromising on quality.</p>
                           <h2>Expert Support:</h2>
                           <p> Our knowledgeable team provides technical support and guidance to help you find the right parts for your needs
                           </p>
<h2>Reliability:</h2>
<p>With a well-established supply chain, we ensure timely delivery of parts.</p>
<h2>Experience:</h2>
<p> Our extensive industry knowledge helps us meet the specific needs of our customers.</p>
<h2>Customer Satisfaction:</h2>
<p>
We prioritize customer satisfaction through excellent service and support.</p>

                             
 

                                        

                           
                        </div>
                    </div>
                </div>

  
            </div>
        </div>
    </div>
  )
}

export default Mechanical
