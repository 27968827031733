import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink, useNavigate } from 'react-router-dom'

const Navbar2 = () => {
    const navigate=useNavigate()
    const handleFilter=(e)=>{
        // alert(e)
    navigate(`/images/${e}`)
    // alert(e)
      }
      const handleElectrical=()=>{
        navigate(`/images/electrical/:slug`)
      }
  return (
    <div>
             <div id="nav" className='mt-2 align-item-center'>
            <div className="container-fluid">
                <nav className="navbar navbar-expand-md bg-dark navbar-dark w-100 justify-content-between" >

                <div className='btn btn-light' value='electrical'onClick={handleElectrical}>Electrical(Substation)</div>
                        
                        <button className='btn btn-light' value='mechanical' onClick={(e)=>handleFilter(e.target.value)}>Mechanical</button>
                                      <button className='btn btn-light' value='chemical' onClick={(e)=>handleFilter(e.target.value)}>Chemical</button>
                                      <button className='btn btn-light' value='other' onClick={(e)=>handleFilter(e.target.value)}>Others</button>
         
                       
                        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    {/* <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav ml-auto">

                       </div> */}
                    {/* </div> */}
                </nav>
            </div>
        </div>
    </div>
  )
}

export default Navbar2
