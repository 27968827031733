import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './banner.css'
const data=[
  {image:'./banner/b (1).jpg'},
  {image:'./banner/b (2).jpg'},
  {image:'./banner/b (3).jpg'},
  {image:'./banner/b (4).jpg'},
   {image:'./banner/b (6).jpg'},
  {image:'./banner/b (7).jpg'},
  {image:'./banner/b (8).jpg'},
]

// console.log(data?.image[0],'data');

const Banner = () => {
 const settings = {
    dots: false,
    infinite: true,
    speed: 7000,
    autoplay: true,
    pauseOnHover: true,
    fade: true,
    autoplaySpeed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };
  return (
    <div>
      <Slider {...settings}>
{data?.map((item)=>{
  // console.log(item,'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb')
  return(
    <>
    
    <div className="slider-div">
    <img src={item?.image} alt={item.image}/>
    </div>
      
    </>
   
  )
})}
 </Slider>

      
    </div>
  )
}

export default Banner
