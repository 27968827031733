import React from 'react'

const Salt = () => {
  return (
    <div>
      <div className="about mt-3">
            <div className="container">
                <div className="section-header">
                    <h2>  Household and Wellness Salt Products</h2>
                    <p className='text-left'>
                  
                    Whether you’re seeking industrial-grade solutions or wellness essentials, ABDUL LATIF TRADING & DEVELOPMENT brings you the finest salt products for all your needs.
                    Our household salts combine the finest ingredients with therapeutic benefits, bringing nature's purity to your home:
                    
                    </p>
                    </div>            <div className="row justify-content-center">
                    <div className="col-md-6">
                       
                        <div className="about-content m-0 w-100">
                            <h2>Himalayan Pink Salt</h2>
                            <p>
                            Renowned for its rich mineral content and unique flavor, perfect for cooking and seasoning.                                </p>
                            <h2>Flavored Salts</h2>
                            <p>Enhance culinary creations with a range of delicious, gourmet flavors.</p>
                            <h2>Decorative & Therapeutic Salt Lamps</h2>
                            <p>Add a touch of serenity to any space with our beautiful, handcrafted salt lamps</p>
                            <h2>USB Lamps</h2>
                            <p>Compact and perfect for desk spaces, enhancing your environment with a soft glow.</p>
                            <h2>Night Lamps and Tea Lights</h2>
                            <p>Ideal for a calming ambiance.</p>
                           
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div>
                    </div>
            
                    <div className="col-md-6">
                       
                        <div className="about-content m-0 w-100">
                        <h2>Beauty & Spa Collection</h2>
<p>Indulge in self-care with our naturally enriching spa products</p>
<h2>Massage Stones and Scrubbers</h2>
<p>Enjoy the soothing effects of salt-infused massage.</p>
<h2>Masks and Therapy Pillows</h2>
<p> Naturally rejuvenate skin and relieve tension.</p>
<h2>Shampoos and Scrubs</h2>
<p> Enhance your beauty routine with mineral-rich, salt-based treatments.</p>

                        </div></div>
                        <div className="section-header">
                          <h2>Industrial Salt Solutions for Specialized Applications</h2>
                       <p className='text-left'>

                       Our industrial salts are expertly crafted to meet the highest standards in various industries. Each product is formulated for specific applications, ensuring quality and reliability in demanding environments

                       </p>
          </div>         
          
                     <div className="row justify-content-center">
                    <div className="col-md-6">
                       
                        <div className="about-content m-0 w-100">
                        <h2>Oil Drilling Grade Salt</h2><p> Engineered for optimal performance in oil drilling operations, supporting efficient and effective drilling processes.</p>
<h2>Oil Refinery Grade Salt</h2><p>  Ideal for refining applications, this salt offers the purity and consistency required for oil refining. .</p>
<h2>Road De-icing / Snow Melting Salt</h2><p>  Designed to keep roads clear and safe, our snow-melting salt is essential for effective winter road management. .</p>
<h2>Salt Tablets for Water Softening</h2><p>  Formulated to effectively soften water, our salt tablets are perfect for industrial water softening systems. .</p>
<h2>Sheep Casing Grade Salt</h2><p> Specially processed for the preservation and treatment of natural sheep casings in the food industry. .</p>

          </div>
        </div>
        <div className="col-md-6">
                       
                       <div className="about-content m-0 w-100">
                       	<h2>Soap Detergent and Glycerine Ultra-Pure Bromine</h2><p>  Ultra-pure bromine salt tailored for use in soap, detergent, and glycerine production, ensuring a high level of purity and effectiveness.</p>
	<h2>Textile Dyeing Grade Salt</h2><p>  Essential for vibrant and consistent dyeing results in the textile industry. .</p>
	<h2>Water Softening Grade Salt</h2><p>  A premium-grade salt designed to prevent scaling and enhance water quality in industrial water softening applications. .</p>
	<h2>Flossy Salt</h2><p> A fine, versatile salt with a range of applications across different industries, known for its refined texture and quality. .</p>


                        </div></div>
                        <p>Our industrial salts support high-performance needs across sectors, from oil and gas to textiles and water treatment, delivering the quality and effectiveness your business demands.</p>
        
        </div></div>
    </div>
    </div></div>
  )
}

export default Salt
