import React from 'react'
import './ourclient.css'
const OurClients = () => {
    const data=[
      {title:'Office #. 3',image:'/images/Rb/rb (1).jpg'},
      {title:'NTN 7359859-1',image:'/images/Rb/rb (2).jpg'},
      {title:'Reg #.AM-601',image:'/images/Rb/rb (1).png'},
      {title:'SRN 5911',image:'/images/Rb/rb (2).jpeg'},
        {title:'AN11187529484',image:'/images/Rb/rb (1).jpeg'},
      
       
       
        
        {title:'850405778',image:'/images/Rb/rb (2).png'}
      ]
  return (
    <div>
          <div className="clients">
            <div className="container">
                <div className="section-header">
                    <h2>We Affiliate With</h2>
                    <p>
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac lacus eget nunc imperdiet   */}
                    </p>
                </div>
                <div className="inner-rb">
      {
        data?.map((item)=>{return(
          <>
          <div className="rb-inner-div">
          <div className="rb-map">
          <img src={item.image} alt={item.image}/>
          </div>
          <div className="title">{item?.title}</div>
          </div>
          </>
        )})

      }
     </div>

            </div>
        </div>
    </div>
  )
}

export default OurClients
