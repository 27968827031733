import React from 'react'
import AboutUs from '../AboutUs/AboutUs'
import OurServices from '../Services/OurServices'
import OurClients from '../OurClients/OurClients'
import ContactUs from '../ContactUs/ContactUs'
import ChooseUs from '../ChooseUs/ChooseUs'
import Banner from '../Banner/Banner'
import OurLocation from '../OurLocation/OurLocation'

const Home = () => {
  return (
    <div>
      <Banner/>
      <AboutUs/>
      <ChooseUs/>
      <OurServices/>
      <OurClients/>
      <ContactUs/>
      {/* <OurLocation/> */}
    </div>
  )
}

export default Home
