import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const OurServices = () => {
  const navigate=useNavigate()
    const data=[
        {name:'Chemical',image:'/sodaashlight.jpeg',details:'We Provide chemicals for industry to comprise the companies and other organizations that develop and produce industrial, specialty and other items.',link:'/chemical'},
        {name:'Mechanical',image:'/images/mechanical/mc (1).jpeg',details:'Mechanical parts trading involves sourcing and distributing essential components like bearings, gears, seals, and fasteners to various industries. These parts are crucial in maintaining and repairing machinery across sectors such as automotive, aerospace, manufacturing, and construction.',link:'/mechanical'},
        {name:'Electrical',image:'/images/electronics/crashBarriers/cb (1).jpeg',details:'We provide substation equipment, custom engineering, testing and field-ready rentals. Flexible high-voltage solutions offer multiple options for power generators, distributors and industrial consumers.',link:'/electrical'},
        // {name:'Others',image:'/images/others/others (3).jpg',details:'Detergents, Chemicals, Soaps Textile, Paper, Food Casting Industry',link:'/others'},
     
      ]
      const handleClick=(e)=>{
        navigate(`${e}`)

      }
      useEffect(()=>{
        window.scroll(0,0)
          },[])
  return (
    <div>
      <div className="service">
            <div className="container">
                <div className="section-header">
                    <h2>Services</h2>
                    <p>
                        {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ac lacus eget nunc imperdiet  */}
                    </p>
                </div>
                <div className="row justify-content-between">
                {data?.map((item)=>{
            return(
              <>
            
                <div className="col-sm-6 col-md-3 col-lg-3" onClick={(e)=>handleClick(item.link)}
                  style={{cursor:'pointer'}}>
                        <div className="service-item">
                            <div className="service-icon" >
                                 <img src={item.image} alt=''/>
                            </div>
                            <div className="service-detail overflow-hidden" >
                                <h4><a href="/">{item.name}</a></h4>
                               {item?.details.length>200?<p>{item.details.substring(0,150)}...</p>:<p>{item.details}</p>}
                               <button className='btn btn-light mt-3'>Read More</button>
                            </div>
                            
                        </div>
                    </div>

              </>
            )
          })}
                  
 
                </div>
            </div>
        </div>
    </div>
  )
}

export default OurServices
