import React, { useEffect } from 'react'

const Electrical = () => {
  useEffect(()=>{
    window.scroll(0,0)
      },[])
  return (
    <div>
      <div className="about mt-3">
            <div className="container">
                <div className="section-header">
                    <h1 className='text-center'>Electrical (Mechanical Sub-Station)</h1>
                    <h2>We are working in a good environment with SEC/NG since 2010.</h2>
                    <p className='text-left'>
                  </p></div>                
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="about-img">
                            <img src="/images/electronics/crashbarriers/cb (1).jpeg" alt="" className="img-fluid"/>
                        </div>
                        <div className="about-content">
                            <h5 className='text-center'>
                                Below mentioned all material can be available with HDG according to order. 
                                We believe to deliver order in a short time ASAP .Here we expecting swift response 
                                from your side with good news.Hope so our this journey will go very long in a good 
                                environment. Thank you</h5>
<p>All types of transformer rails.</p>
<p>Transformer Rails accessories .</p>
<p>Steel Grating (Supply & installation)</p>
<p>Access ladders</p>
<p>GIS crane Beams.</p>
<p>Crash Barriers completed set .</p>
<p>Transformer area pulling hooks.</p>
<p>Pipes for crash barriers.</p>
<p>All type & sizes of steel plates and anchor Bolts.</p>
                           
                        </div>
                    </div>
                </div>

  
            </div>
        </div>
    </div>
  )
}

export default Electrical
