import React from 'react'

const ChooseUs = () => {
  return (
    <div>
      <div className="about mt-3">
            <div className="container">
                <div className="section-header">
                    <h2>  Why Choose Us? </h2>
                    <p className='text-left'>
                  
                    In today’s bustling marketplace, where options are abundant and choices are often overwhelming, selecting the right partner for your needs is crucial. At Abdul Latif Trading & Development , we believe that our unique blend of experience, expertise, and dedication sets us apart from the rest. Here’s why you should choose us:    </p>          </div>
            <div className="row justify-content-center">
                    <div className="col-md-6">
                       
                        <div className="about-content m-0 w-100">
                            <h2>Expertise and Experience</h2>
                            <p>Our team comprises seasoned professionals with years of experience in the industry. We have a deep understanding of the nuances and challenges of our field, allowing us to deliver solutions that are not only effective but also innovative. Whether you need [specific service/product], our experts are equipped with the knowledge and skills to exceed your expectations.</p>
                            <h2>Customer-Centric Approach.</h2>
                            <p>We place our clients at the heart of everything we do. Our approach is centered around understanding your unique needs and tailoring our services to meet those specific requirements. From the initial consultation to the final delivery, we ensure that your vision is realized with precision and care</p>
                            <h2>Quality and Reliability</h2>
                            <p>Quality is the cornerstone of our operations. We adhere to the highest standards of quality in every project we undertake, ensuring that our deliverables are reliable and durable. Our commitment to excellence means that you can trust us to deliver results that stand the test of time.</p>
                           
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div>
                    </div>
            
                    <div className="col-md-6">
                       
                        <div className="about-content m-0 w-100">
                            <h2>Competitive Pricing</h2>
                            <p>We understand the importance of value for money. Our pricing is competitive, offering you top-notch quality without compromising your budget. We strive to deliver the best possible outcome within your financial parameters.</p>
                            <h2>Dedication to Continuous Improvement</h2>
                            <p>We are committed to continually improving our services and processes. By staying current with industry trends and investing in ongoing training and development, we ensure that we are always at the forefront of our field.</p>
                            <h2>Commitment to Sustainability</h2>
                            <p>Sustainability is a core value for us. We are dedicated to implementing practices that minimize environmental impact and promote long-term sustainability. By choosing us, you are partnering with a company that values and actively contributes to the preservation of our planet.</p>
                           
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div></div>
                        <div className="section-header">
                       <p className='text-left'> Choosing the right partner can make all the difference in achieving your goals. At Abdul Latif Trading & Development , we are dedicated to providing exceptional service and delivering results that surpass your expectations. Discover the difference that our expertise, commitment, and innovative approach can make. Choose us, and let’s build something great together.
Feel free to reach out to us for more information or to discuss how we can assist with your needs. We look forward to the opportunity to work with you!
</p>
          </div>          </div>
          </div>
        </div>
    </div>
  )
}

export default ChooseUs
