import React from 'react'

const AboutUs = () => {
  return (
    <div>
          <div className="about p-0 mt-1">
            <div className="container">
            <h2 className='text-center'>Welcome to Our Site</h2>
                <div className="section-header">
                    
                    <h2>abdul Latif trading and development </h2>
                    <p className='text-left'>
                    M/S ABDUL LATIF TRADING & DEVELOPMENT is a registered firm with Federal Board of Revenue (FBR) under Registration No 7359859-1 since 23rd of December 2016. ALTD is registered with Haripur chamber of commerce & industry and started its business since January 2017.
Objective of ALTD is to provide quality products and services with efficient and effective resource utilization while maintaining the best business standard and ethics. Prior to starting business in Pakistan, we have 7 year experience in Saudi Arabia doing business in trading and construction. Saudi Arabia electric supply company was one of our main client, where we under joined following projects as sub-contractor.
</p>               </div>
                
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="about-img">
                            <img src="/images/others/others (40).jpg" alt="" className="img-fluid"/>
                         
                        </div>
                        <div className="about-content">
                            
                            <p>
                            Based in District Haripur we have already established good business terms with leading Organizations of Pakistan like HMC TAXILA, NRTC Haripur, WNG Wah Cantt and other local industries and traders. In such a short span of time it is our hard work and experience that we have reached recognition in market.
  We hope to play positive role in Pakistan's economy by bringing competence and quality in market
                      </p>
                      <p>We confirm that our business relation will be based upon mutual respect and enhancing of betterment. We will make sure that there will be no compromise on quality of services on our part.</p>
  <h2 className='text-right'>(Muhammad Zakariyya)</h2>
  <h2 className='text-right'>Chief Executive</h2>
                           
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <div className="about-img">
                            <img src="images/electronics/crashBarriers/cb (2).jpeg" alt="" className="img-fluid" style={{height:'405px'}}/>
                        </div>
                        <div className="about-content">
                            <h2>Constracts in Saudi Arabia</h2>
                            <p>1. Saudi Service For Electro Mechanical Works co. (SSEM).</p>
                            <p>2. AlToukhi Company of industry, trading and contracting.</p>
                            <p>3. Al Babtain Contracting company.</p>
                            <p>4. Nour Communication company.</p>
                            <p>5. Al fanar.</p>
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about-img">
                            <img src="images/electronics/crashBarriers/cb (4).jpeg" alt="" className="img-fluid"/>
                        </div>
                        <div className="about-content ts">
                            <h2>
                            Projects as Sub-Contractor</h2>
                            <p>  1. Hail-2cc simple cycle to combined cycle power plant.</p>
                            <p> 2. 380/132 kv ss bsp 9014 </p>
                            <p> 3. 380/132 kv ss bsp 9018</p>
                            <p>  4. 380/13.8 kv sub station 8130</p>
                            {/* <a className="btn" href="#">Read More</a> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs
