import React from 'react'
const Props = (item) => {
 const handleClick=(e)=>{
window.open(e,'_blank')
 }
  return (
    <div>
    
       <img src={item.image} alt={item.image} className="img-fluid" onClick={()=>handleClick(item.image)}/>
      </div>
   
  )
}

export default Props
