import React from 'react'
import { useParams } from 'react-router-dom'
// import ImageBar from '../Navbar/ImageBar';
import data from '../imageList.json'
import Props from '../pages/Props';
import Navbar2 from '../Navbar/Navbar2';
const ImageGallery = () => {
    const {slug}=useParams()
    console.log(slug,'slug');
    
  return (

    <>
     <Navbar2/>
            <div className="portfolio">
            <div className="container">
           
                <div className="section-header">
                <h2>{slug===':slug'?'All Images':`${slug} Images`}</h2>
                    <p>
                                           </p>
                </div>

                <div className="row portfolio-container">
                {data?.filter((item)=>item.image.includes(slug===':slug'?'others':slug))
        .map((item)=>{return(
            <>
             <div className="col-lg-4 col-md-6 portfolio-item">
                        <div className="portfolio-img">
                            <Props image={item.image}/>
                            {/* <a href={`${item.image}`} data-lightbox="portfolio" data-title="ALTDC IMAGE" className="link-preview" title="Preview"><i className="ion-md-eye"></i></a> */}
                            {/* <a href="" className="link-details" title="More Details"><i className="ion-md-open"></i></a> */}
                        </div>                      
                    </div>
         
           
            </>
        )})}

                 




               

                 
                </div>
            </div>
        </div>
    
    </>


  )
}

export default ImageGallery
