import React from 'react'
import { Link } from 'react-router-dom'

const Foooter = () => {
  return (
    <div>
       <div className="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 footer-links">
                            <h4>About Us</h4>
                            <ul>
                                <li><i className="ion-ios-arrow-forward"></i> <a href="/">Home</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href="/aboutus">About us</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href="services">Our services</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href="#">Terms & condition</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href="#">Privacy policy</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="ion-ios-arrow-forward"></i> <a href='/chemical' target='_blank'>Chemical</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href='/mechanical' target='_blank'>Mechanical</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href='/electrical' target='_blank'>Electrical</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href='/location' target='_blank'>Our Location</a></li>
                                <li><i className="ion-ios-arrow-forward"></i> <a href='tel:00923135887820' ><span className='text-light'>Whats App</span></a></li>
                              
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                            Office #3,First Floor<br/>
                            Ghakar Plaza,Shakkar Shah Road<br/>
                            Haripur,Pakistan <br/>
                                <strong>Phone:</strong><a href='tel:00923135887820' ><span className='text-light'>+92 313 588 78 20</span></a><br/>
                                <strong>Email:</strong>altdc@hotmail.com<br/>
                            </p>

                            <div className="social-links">
                                {/* <a href="https://freewebsitecode.com/"><i className="ion-logo-twitter"></i></a> */}
                                <a href="https://www.facebook.com/profile.php?id=61562322567895" target='_blank'><i className="ion-logo-facebook"></i></a>
                                <a href="https://www.linkedin.com/in/taniya-malik-3a0146229/" target='_blank'><i className="ion-logo-linkedin"></i></a>
                                <a href="https://www.instagram.com/uniquesoftwarehouse/" target='_blank'><i className="ion-logo-instagram"></i></a>
                                <a href="https://www.youtube.com/@uniquesoftwarehouse" target='_blank'><i className="ion-logo-youtube"></i></a>
                            </div>

                        </div>

                        {/* <div className="col-lg-3 col-md-6 footer-newsletter">
                            <h4>Subscription</h4>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                            <form action="" method="post">
                                <input type="email" name="email"/><input type="submit"  value="Subscribe"/>
                            </form>
                        </div> */}

                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 copyright text-light">
                        Copyright &copy; 2017 <a href="https://uniquesoftwarehouse.com/">ALTDC</a>. All Rights Reserved
                    </div>
                    <div className="col-md-6 credit text-light">
		
                        Designed and Developed by <a href="https://uniquesoftwarehouse.com/">Unique Software House</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Foooter
