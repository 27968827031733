import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink, useNavigate } from 'react-router-dom'
import Navbar2 from './Navbar2'

const Navbar3= () => {
    const navigate=useNavigate()
    const handleFilter=(e)=>{
        // alert(e)
    navigate(`/images/electrical/${e}`)
    // alert(e)
      }
 
  return (
    <div>
        <Navbar2/>
        <div id="nav" className='mt-2 alin-item-center'>
            <div className="container-fluid">
                <nav className="navbar navbar-expand-md bg-dark navbar-dark w-100 justify-content-between" >

<button className='btn btn-light' value='accessLadders' onClick={(e)=>handleFilter(e.target.value)}>Access Ladders</button>
<button className='btn btn-light' value='crashBarriers' onClick={(e)=>handleFilter(e.target.value)}>Crash Barriers</button>
<button className='btn btn-light' value='GISCraneBeams' onClick={(e)=>handleFilter(e.target.value)}>Gis Crane Beams</button>
<button className='btn btn-light' value='pullingHooks' onClick={(e)=>handleFilter(e.target.value)}>Pulling Hooks</button>
<button className='btn btn-light' value='rail' onClick={(e)=>handleFilter(e.target.value)}>Rails</button>
<button className='btn btn-light' value='steelWorksAndHDG' onClick={(e)=>handleFilter(e.target.value)}>Steel Works & Hdg</button>      
 {/* <span className="navbar-toggler-icon"></span> */}
<button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>   
                    
                    {/* <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav ml-auto">
</div></div> */}
                </nav>
            </div>
        </div>
    </div>
  )
}

export default Navbar3
